import React, { FC } from 'react'
import { CmsHtmlContent } from 'src/components/typography/CmsHtmlContentFormats'
import tw from 'twin.macro'
import styled from 'styled-components'
import { IPersonProfile } from 'src/components/preson-profile/PersonProfile'

interface IDesktopFullContentProps {
  profile: IPersonProfile
  className?: string
}

const StyledDetailedInformation = styled.div<{ isFullInfoBox: boolean }>`
  p {
    ${({ isFullInfoBox }) => (isFullInfoBox ? '' : tw`font-bold`)}
  }
`

const DesktopFullContent: FC<IDesktopFullContentProps> = ({
  profile,
  className,
}) => (
  <div className={`h-full flex items-center justify-center px-12 ${className}`}>
    <div className="flex items-start justify-center w-full max-h-full mt-12 max-w-screen-lg py-16">
      <div className="relative flex flex-shrink-0 w-1/4 max-h-full">
        <div className="text-white text-left">
          <img
            src={profile.photo.url}
            alt={`${profile.name} ${profile.surname}`}
          />
          <p className="mt-6 bg-almostBlackBlue text-4xl text-brightYellow px-2 md:px-3 leading-none font-thin inline-block">
            {profile.name}
          </p>
          <div />
          <p className="bg-almostBlackBlue text-4xl text-brightYellow sm:mt-1 px-2 md:px-3 leading-none inline-block left-0 font-bold">
            {profile.surname}
          </p>
          <p className="text-left text-deepDarkBlue mt-4 text-2xl">
            {profile.organization}
          </p>
        </div>
      </div>
      <div className="max-h-full ml-8">
        <StyledDetailedInformation isFullInfoBox={profile.isFullInfoBox}>
          <CmsHtmlContent
            className=""
            dangerouslySetInnerHTML={{ __html: profile.detailedInformation }}
          />
        </StyledDetailedInformation>
      </div>
    </div>
  </div>
)

export default DesktopFullContent
