import React, { FC } from 'react'
import config from 'src/../tailwind.config'
import SectionTitle from 'src/components/typography/SectionTitle'
import RelatedDocuments from 'src/components/document/RelatedDocuments'
import ContentPageContainer from 'src/components/layout/ContentPageContainer'
import SingleDepartment, {
  IDepartment,
} from 'src/components/organisation/SingleDepartment'

interface IDepartmentsProps {
  model: any
}

const Departments: FC<IDepartmentsProps> = ({ model }) => {
  const departments: Array<IDepartment> = [
    {
      doubleTitle: model.doubleTitle[0],
      content: model.content2,
      profiles: model.personProfiles,
    },
    {
      doubleTitle: model.doubleTitle3[0],
      content: model.content4,
      profiles: model.personProfiles3,
    },
    {
      doubleTitle: model.doubleTitle4[0],
      content: model.content5,
      profiles: model.personProfiles4,
      secondContent: model.content6,
    },
    {
      doubleTitle: model.doubleTitle2[0],
      content: model.content3,
      profiles: model.personProfiles2,
    },
    {
      doubleTitle: model.doubleTitle5[0],
      content: model.content7,
      profiles: model.personProfiles5,
    },
  ]

  const listOfDepartments = () =>
    departments.map((department) => (
      <SingleDepartment
        department={department}
        key={department.doubleTitle.mainTitle}
      />
    ))

  return (
    <ContentPageContainer noPadding>
      <div className="mt-8 mb-16">{listOfDepartments()}</div>
      <SectionTitle className="mt-24 hidden">Related documents</SectionTitle>
      <RelatedDocuments
        className="mb-16 hidden"
        items={model.relatedDocuments2[0].relatedDocuments}
        color={config.theme.extend.colors.black}
      />
    </ContentPageContainer>
  )
}

export default Departments
