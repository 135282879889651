import React, { FC } from 'react'
import { CmsHtmlContent } from 'src/components/typography/CmsHtmlContentFormats'
import { IDoubleTitle } from 'src/components/typography/DoubleTitle'
import Profiles from 'src/components/preson-profile/ListOfPersonProfiles'
import { IPersonProfile } from 'src/components/preson-profile/PersonProfile'
import FoldableSection from 'src/components/organisation/FoldableSection'
import themeColors from 'src/helpers/theme-colors'

export interface IDepartment {
  doubleTitle: IDoubleTitle
  content: string
  profiles: Array<IPersonProfile>
  secondContent?: string
}

interface ISingleDepartmentProps {
  department: IDepartment
  titleIndent?: string
}

const SingleDepartment: FC<ISingleDepartmentProps> = ({ department }) => (
  <FoldableSection
    doubleTitle={department.doubleTitle}
    color={themeColors.deepDarkBlue}
  >
    <CmsHtmlContent dangerouslySetInnerHTML={{ __html: department.content }} />
    <Profiles profiles={department.profiles} className="mt-12" />
    {department.secondContent && (
      <CmsHtmlContent
        className="mt-8"
        dangerouslySetInnerHTML={{ __html: department.secondContent }}
      />
    )}
  </FoldableSection>
)

export default SingleDepartment
