import { gsap, ScrollTrigger } from 'gsap/all'
import { useEffect, useRef } from 'react'
import { useIsLargeScreen } from 'src/helpers/layout'

gsap.registerPlugin(ScrollTrigger)

export const useBodiesAnimation = () => {
  const timeline = useRef(gsap.timeline())
  const isLarge = useIsLargeScreen()

  useEffect(() => {
    if (!isLarge) {
      return
    }

    gsap.set('.desktop #claim-2 > line', {
      scaleX: 0,
      x: '50%',
    })

    ScrollTrigger.create({
      trigger: '.desktop #logo_full',
      start: '150px bottom',
      onEnter: () => {
        timeline.current.to('.desktop #logo_full', {
          opacity: 1,
          duration: 0.75,
        })
      },
    })

    ScrollTrigger.create({
      trigger: '.desktop #logo_full',
      start: '150px bottom',
      onEnter: () => {
        timeline.current.to(
          '.desktop #claim',
          {
            opacity: 1,
            duration: 0.75,
          },
          0
        )
      },
    })

    ScrollTrigger.create({
      trigger: '.desktop #logo_full',
      start: '150px bottom',
      onEnter: () => {
        timeline.current.to('.desktop #claim-2 > line', {
          scaleX: 1,
          duration: 0.75,
          x: 0,
        })
      },
    })

    ScrollTrigger.create({
      trigger: '.desktop #founders',
      start: '150px bottom',
      onEnter: () => {
        timeline.current.to('.desktop #founders', {
          opacity: 1,
          duration: 0.75,
        })
      },
    })

    ScrollTrigger.create({
      trigger: '.desktop #internal',
      start: '150px bottom',
      onEnter: () => {
        timeline.current.to('.desktop #internal', {
          opacity: 1,
          duration: 0.75,
        })
      },
    })

    ScrollTrigger.create({
      trigger: '.desktop #external',
      start: '150px bottom',
      onEnter: () => {
        timeline.current.to('.desktop #external', {
          opacity: 1,
          duration: 0.75,
        })
      },
    })

    ScrollTrigger.sort()
  })
}

export default {}
