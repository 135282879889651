import React, { FC, useState } from 'react'
import { IImage } from 'src/components/parallax-image'
import PersonProfilePopup from 'src/components/preson-profile/PersonProfilePopup'

export interface IPersonProfile {
  id: string
  name: string
  surname: string
  organization: string
  detailedInformation: string
  isFullInfoBox: boolean
  photo: IImage
}
interface IPersonProfileProps {
  profile: IPersonProfile
}
const PersonProfile: FC<IPersonProfileProps> = ({ profile }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <button
        className="border-b-2 border-almostBlackBlue w-full"
        type="button"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="relative mb-4 md:mb-8" style={{ overflowX: 'clip' }}>
          <div className="absolute -bottom-3 md:-bottom-4 left-3  text-white text-left">
            <p
              className="bg-almostBlackBlue text-brightYellow px-2 md:px-3 leading-none font-thin inline-block"
              style={{ fontSize: '0' }}
            >
              <span className="text-lg sm:text-3xl md:text-2xl xl:text-xl 2xl:text-3xl">
                {profile.name}
              </span>
            </p>
            <div />
            <p
              className="bg-almostBlackBlue  text-brightYellow mt-0.5 sm:mt-1 px-2 md:px-3 leading-none inline-block font-bold"
              style={{ fontSize: '0' }}
            >
              <span className="text-lg sm:text-3xl md:text-2xl xl:text-xl 2xl:text-3xl">
                {profile.surname}
              </span>
            </p>
          </div>
          <img
            src={profile.photo.url}
            alt={`${profile.name}
            ${profile.surname}`}
          />
        </div>
        <p className="text-left">{profile.organization}</p>
      </button>
      <PersonProfilePopup
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        profile={profile}
      />
    </>
  )
}

export default PersonProfile
