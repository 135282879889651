import React, { FC } from 'react'
import PageTitle from 'src/components/typography/PageTitle'
import Article from 'src/components/typography/Article'
import Section from 'src/components/typography/Section'
import config from 'src/../tailwind.config'
import RelatedDocuments from 'src/components/document/RelatedDocuments'
import SectionTitle from 'src/components/typography/SectionTitle'
import ContentPageContainer from 'src/components/layout/ContentPageContainer'
import { CmsHtmlContent } from 'src/components/typography/CmsHtmlContentFormats'
import { IRelatedDocument } from 'src/components/document/document-types'
import { IImage } from 'src/components/parallax-image'
import StyledHtmlColorOverwrite from 'src/components/typography/StyledHtmlColorOverwrite'
import StyledSubtitleColorOverwrite from 'src/components/typography/StyledSubtitleColorOverwrite'
import themeColors from 'src/helpers/theme-colors'
import BodiesDesktop from 'src/images/SVG/organisation/bodies-desktop.inline.svg'
import BodiesDesktopDe from 'src/images/SVG/organisation/bodies-desktop-de.inline.svg'
import BodiesDesktopFr from 'src/images/SVG/organisation/bodies-desktop-fr.inline.svg'
import BodiesDesktopEs from 'src/images/SVG/organisation/bodies-desktop-es.inline.svg'
import BodiesMobile from 'src/images/SVG/organisation/bodies-mobile.inline.svg'
import BodiesMobileDe from 'src/images/SVG/organisation/bodies-mobile-de.inline.svg'
import BodiesMobileFr from 'src/images/SVG/organisation/bodies-mobile-fr.inline.svg'
import BodiesMobileEs from 'src/images/SVG/organisation/bodies-mobile-es.inline.svg'
import { useBodiesAnimation } from 'src/components/layout/animations/bodies-animations'
import { useBodiesAnimationMobile } from 'src/components/layout/animations/bodies-animations-mobile'

interface IBodiesSectionModel {
  subtitle: string
  title: string
  content: string
  relatedDocuments: Array<{ relatedDocuments: IRelatedDocument[] }>
  image: IImage
  mobileImage: IImage
}

interface IBodiesSectionProps {
  model: IBodiesSectionModel
  language: string
}

const footballAssociationBoardAlt = `
The international football association board:
The FA, FA Walles, Scottish FA, Irish Football Northern Ireland Association and FIFA.
Founders of The IFAB and international representation:
Board of Directors and Technical Subcommitte.
Internal IFAB bodies supported by the executive office:
Football Advisory Panel and Technical Advisory Panel.
External Advisory Panels to support decision-making process.`

const BodiesSection: FC<IBodiesSectionProps> = ({ model, language }) => {
  useBodiesAnimation()
  useBodiesAnimationMobile()

  const bodiesDesktopClasses = 'w-full overflow-visible hidden md:block desktop'
  const bodiesMobileClasses = 'w-full overflow-visible block md:hidden mobile'

  const getBodiesDesktop = () => {
    switch (language) {
      case 'en':
        return (
          <BodiesDesktop
            className={bodiesDesktopClasses}
            alt={footballAssociationBoardAlt}
          />
        )
      case 'de':
        return (
          <BodiesDesktopDe
            className={bodiesDesktopClasses}
            alt={footballAssociationBoardAlt}
          />
        )
      case 'es':
        return (
          <BodiesDesktopEs
            className={bodiesDesktopClasses}
            alt={footballAssociationBoardAlt}
          />
        )
      case 'fr':
        return (
          <BodiesDesktopFr
            className={bodiesDesktopClasses}
            alt={footballAssociationBoardAlt}
          />
        )

      default:
        return <></>
    }
  }

  const getBodiesMobile = () => {
    switch (language) {
      case 'en':
        return (
          <BodiesMobile
            className={bodiesMobileClasses}
            alt={footballAssociationBoardAlt}
          />
        )
      case 'de':
        return (
          <BodiesMobileDe
            className={bodiesMobileClasses}
            alt={footballAssociationBoardAlt}
          />
        )
      case 'es':
        return (
          <BodiesMobileEs
            className={bodiesMobileClasses}
            alt={footballAssociationBoardAlt}
          />
        )
      case 'fr':
        return (
          <BodiesMobileFr
            className={bodiesMobileClasses}
            alt={footballAssociationBoardAlt}
          />
        )
      default:
        return <></>
    }
  }

  return (
    <div className="bg-brightYellow overflow-hidden">
      <ContentPageContainer>
        <Section isNoMargin>
          <PageTitle isSticked={false}>
            <span className="text-almostBlackBlue">{model.title}</span>
          </PageTitle>
          <StyledSubtitleColorOverwrite>
            {model.subtitle}
          </StyledSubtitleColorOverwrite>
          <div className="mt-8 mb-16">
            {getBodiesDesktop()}
            {getBodiesMobile()}
          </div>
          <Article className="mb-12" noMarginDesktop noMarginMobile>
            <StyledHtmlColorOverwrite color={themeColors.almostBlackBlue}>
              <CmsHtmlContent
                dangerouslySetInnerHTML={{ __html: model.content }}
              />
            </StyledHtmlColorOverwrite>
          </Article>
          <SectionTitle className="hidden">Related documents</SectionTitle>
          <RelatedDocuments
            className="mb-12 hidden"
            items={model.relatedDocuments[0].relatedDocuments}
            color={config.theme.extend.colors.black}
            isBlack
          />
        </Section>
      </ContentPageContainer>
    </div>
  )
}

export default BodiesSection
