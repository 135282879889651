import React, { FC } from 'react'
import PersonProfile, {
  IPersonProfile,
} from 'src/components/preson-profile/PersonProfile'

interface IListOfPersonProfilesProps {
  profiles: Array<IPersonProfile>
  className?: string
}

const ListOfPersonProfiles: FC<IListOfPersonProfilesProps> = ({
  profiles,
  className,
}) => (
  <div
    className={`grid grid-cols-2 md:grid-cols-3 gap-x-4 xl:gap-x-16 gap-y-12 ${className}`}
  >
    {profiles.map((profile) => (
      <PersonProfile profile={profile} />
    ))}
  </div>
)

export default ListOfPersonProfiles
