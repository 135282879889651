import React, { FC } from 'react'
import ContentPageContainer from 'src/components/layout/ContentPageContainer'
import Section from 'src/components/typography/Section'
import Article from 'src/components/typography/Article'
import SectionTitle from 'src/components/typography/SectionTitle'
import RelatedDocuments from 'src/components/document/RelatedDocuments'
import config from 'src/../tailwind.config'
import { Link } from 'gatsby-plugin-react-i18next'
import SemiRoundedButton, {
  ButtonSizes,
} from 'src/components/ui/SemiRoundedButton'
import StyledSubtitleColorOverwrite from 'src/components/typography/StyledSubtitleColorOverwrite'
import SingleMeeting from 'src/components/organisation/SingleMeeting'

interface IMeetingsProps {
  model: any
}

const Meetings: FC<IMeetingsProps> = ({ model }) => (
  <div className="bg-brightYellow overflow-hidden">
    <ContentPageContainer noPadding className="mt-16">
      <Section isNoMargin>
        <StyledSubtitleColorOverwrite>
          {model.subtitle2}
        </StyledSubtitleColorOverwrite>
        <Article className="mb-24" noMarginDesktop noMarginMobile>
          <div>
            <SingleMeeting
              doubleTitle={model.doubleTitle6[0]}
              content={model.content8}
            />
            <SingleMeeting
              doubleTitle={model.doubleTitle7[0]}
              content={model.content9}
            />
            <SingleMeeting
              doubleTitle={model.doubleTitle8[0]}
              content={model.content10}
            />
            <SingleMeeting
              doubleTitle={model.doubleTitle9[0]}
              content={model.content11}
            />
            <SingleMeeting
              doubleTitle={model.doubleTitle10[0]}
              content={model.content12}
            />
          </div>
        </Article>
        <Link
          to={model.buttonLink}
          className="flex justify-center mt-24 mb-24 hidden"
        >
          <SemiRoundedButton isPlain size={ButtonSizes.medium}>
            {model.buttonText}
          </SemiRoundedButton>
        </Link>
        <SectionTitle className="hidden">Related documents</SectionTitle>
        <RelatedDocuments
          className="mb-24 hidden"
          items={model.relatedDocuments3[0].relatedDocuments}
          color={config.theme.extend.colors.black}
          isBlack
        />
        <Link
          to={model.buttonLink2}
          className="flex justify-center mt-24 mb-48 hidden"
        >
          <SemiRoundedButton isPlain size={ButtonSizes.medium}>
            {model.buttonText2}
          </SemiRoundedButton>
        </Link>
      </Section>
    </ContentPageContainer>
  </div>
)

export default Meetings
