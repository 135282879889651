import React, { FC } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'

export interface IDoubleTitle {
  mainTitle: string
  mainTitleIndent: string
  subtitle: string
}

interface IDoubleTitleProps {
  doubleTitle: IDoubleTitle
  className?: string
  isBorderHidden?: boolean
  color: string
}

interface IStyledDoubleTitleProps {
  titleLeftMargin: string
  color: string
}

const StyledDoubleTitle = styled.div<IStyledDoubleTitleProps>`
  h3 {
    strong {
      ${tw`font-bold`}
    }
    margin-left: ${({ titleLeftMargin }) => titleLeftMargin};
  }
  color: ${({ color }) => color};
  border-color: ${({ color }) => color};
`

const DoubleTitle: FC<IDoubleTitleProps> = ({
  doubleTitle,
  className,
  color,
}) => (
  <StyledDoubleTitle
    className={`pb-2.5 ${className ?? ''}`}
    titleLeftMargin={doubleTitle.mainTitleIndent}
    color={color}
  >
    <h3
      className="text-left text-7xl font-thin tracking-widest"
      dangerouslySetInnerHTML={{ __html: doubleTitle.mainTitle }}
    />
    <h4 className="text-lg text-left font-bold mt-1">{doubleTitle.subtitle}</h4>
  </StyledDoubleTitle>
)

export default DoubleTitle
