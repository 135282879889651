import React, { FC } from 'react'
import { CmsHtmlContent } from 'src/components/typography/CmsHtmlContentFormats'
import tw from 'twin.macro'
import styled from 'styled-components'
import { IPersonProfile } from 'src/components/preson-profile/PersonProfile'

interface IMobileContentProps {
  profile: IPersonProfile
  className?: string
}

const StyledDetailedInformation = styled.div<{ isFullInfoBox: boolean }>`
  p {
    ${({ isFullInfoBox }) => (isFullInfoBox ? '' : tw`font-bold`)}
  }
`

const MobileContent: FC<IMobileContentProps> = ({ profile, className }) => (
  <div className="container">
    <div className={`w-full p-8 ${className}`}>
      <div className="relative mb-6 md:mb-8" style={{ overflowX: 'clip' }}>
        <div className="absolute -bottom-3 md:-bottom-4 left-3  text-white text-left">
          <p className="bg-almostBlackBlue text-3xl text-brightYellow px-2 md:px-3 leading-none font-thin inline-block">
            {profile.name}
          </p>
          <div />
          <p className="bg-almostBlackBlue text-3xl text-brightYellow sm:mt-1 px-2 md:px-3 leading-none inline-block left-0 font-bold">
            {profile.surname}
          </p>
        </div>
        <img
          src={profile.photo.url}
          alt={`${profile.name}
          ${profile.surname}`}
        />
      </div>
      <p className="text-left border-deepDarkBlue border-b-2">
        {profile.organization}
      </p>
      <StyledDetailedInformation isFullInfoBox={profile.isFullInfoBox}>
        <CmsHtmlContent
          className="mt-6"
          dangerouslySetInnerHTML={{ __html: profile.detailedInformation }}
        />
      </StyledDetailedInformation>
    </div>
  </div>
)

export default MobileContent
