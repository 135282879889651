import React, { FC, Dispatch, SetStateAction } from 'react'
import { IPersonProfile } from 'src/components/preson-profile/PersonProfile'
import { LazyMotion, m, domAnimation } from 'framer-motion'
import CloseIcon from 'src/images/icons/close.inline.svg'
import MobileContent from 'src/components/preson-profile/MobileContent'
import DesktopFullContent from 'src/components/preson-profile/DesktopFullContent'
import DesktopShortContent from 'src/components/preson-profile/DesktopShortContent'

interface IPersonProfilePopupProps {
  profile: IPersonProfile
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
}

const animationVariants = {
  hidden: {
    opacity: 0,
    transitionEnd: {
      display: 'none',
    },
  },
  show: {
    display: 'block',
    opacity: 1,
  },
}

const PersonProfilePopup: FC<IPersonProfilePopupProps> = ({
  profile,
  isOpen,
  setIsOpen,
}) => (
  <LazyMotion features={domAnimation}>
    <m.div
      variants={animationVariants}
      initial={isOpen ? 'show' : 'hidden'}
      animate={isOpen ? 'show' : 'hidden'}
      transition={{ duration: 0.25 }}
      className="fixed inset-0 bg-paleBlue z-30 overflow-y-scroll"
    >
      <div className="h-full">
        {profile.isFullInfoBox ? (
          <DesktopFullContent className="hidden md:flex" profile={profile} />
        ) : (
          <DesktopShortContent className="hidden md:flex" profile={profile} />
        )}
        <MobileContent className="md:hidden" profile={profile} />

        <button onClick={() => setIsOpen(!isOpen)} type="button">
          <CloseIcon
            aria-label="Close Icon"
            className="w-6 h-6 fixed right-5 top-8 text-deepDarkBlue stroke-current"
          />
        </button>
      </div>
    </m.div>
  </LazyMotion>
)

export default PersonProfilePopup
