// i18next-extract-mark-ns-start article

import * as React from 'react'
import Layout from 'src/components/layout'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import tw from 'twin.macro'
import styled from 'styled-components'
import BodiesSection from 'src/components/organisation/BodiesSection'
import Departments from 'src/components/organisation/Departments'
import Meetings from 'src/components/organisation/Meetings'

const StyledYellowSectionSeparator = styled.div`
  border-left-width: 50vw;
  border-right-width: 50vw;
  border-top-width: 100px;
  ${tw`border-brightYellow`}
  border-left-color: transparent;
  border-right-color: transparent;
`
const StyledWhiteSectionSeparator = styled.div`
  border-left-width: 50vw;
  border-right-width: 50vw;
  border-top-width: 100px;
  ${tw`border-brightYellow`}
  border-top-color: transparent;
`

const IndexPage = ({ pageContext: { model, language } }) => (
  <>
    <Helmet>
      <title>The IFAB Organisation</title>
    </Helmet>
    <Layout>
      <BodiesSection model={model} language={language} />
      <StyledYellowSectionSeparator />
      <Departments model={model} />
      <StyledWhiteSectionSeparator />
      <Meetings model={model} />
    </Layout>
  </>
)

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default IndexPage
