import React, { FC } from 'react'
import FoldableSection from 'src/components/organisation/FoldableSection'
import { CmsHtmlContent } from 'src/components/typography/CmsHtmlContentFormats'
import { IDoubleTitle } from 'src/components/typography/DoubleTitle'
import StyledHtmlColorOverwrite from 'src/components/typography/StyledHtmlColorOverwrite'
import themeColors from 'src/helpers/theme-colors'

interface ISingleMeetingProps {
  doubleTitle: IDoubleTitle
  content: string
}

const SingleMeeting: FC<ISingleMeetingProps> = ({ doubleTitle, content }) => (
  <FoldableSection
    doubleTitle={doubleTitle}
    color={themeColors.almostBlackBlue}
    className="mt-20"
  >
    <StyledHtmlColorOverwrite color={themeColors.almostBlackBlue}>
      <CmsHtmlContent dangerouslySetInnerHTML={{ __html: content }} />
    </StyledHtmlColorOverwrite>
  </FoldableSection>
)

export default SingleMeeting
