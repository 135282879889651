import React, { FC, useEffect, useState } from 'react'
import PlusIcon from 'src/components/icons/PlusIcon'
import HeightAnimation from 'src/components/layout/HeightAnimation'
import DoubleTitle, {
  IDoubleTitle,
} from 'src/components/typography/DoubleTitle'
import { isLargeScreen } from 'src/helpers/layout'
import styled from 'styled-components'
import tw from 'twin.macro'

interface ITitleWrapperProps {
  separatorColor: string
}

const TitleWrapper = styled.div<ITitleWrapperProps>`
  ${tw`flex justify-between items-center border-b-2`}
  border-color: ${({ separatorColor }) => separatorColor};
`

interface IFoldableSectionProps {
  doubleTitle: IDoubleTitle
  color: string
  children: JSX.Element | JSX.Element[]
  className?: string
}

const FoldableSection: FC<IFoldableSectionProps> = ({
  doubleTitle,
  color,
  children,
  className,
}) => {
  const [isOpen, setIsOpen] = useState(isLargeScreen())

  useEffect(() => {
    const openIfLargeScreen = () => {
      if (isLargeScreen()) {
        setIsOpen(true)
      }
    }

    window.addEventListener('resize', openIfLargeScreen)

    return () => {
      window.removeEventListener('resize', openIfLargeScreen)
    }
  }, [])

  const rotateAnimation = `transition-all linear duration-500 transform ${
    isOpen ? 'rotate-45' : 'rotate-0'
  }`

  const handleClick = () => {
    if (!isLargeScreen()) {
      setIsOpen(!isOpen)
    }
  }

  return (
    <div className={`w-full ${className ?? ''} `}>
      <button
        type="button"
        onClick={handleClick}
        className="mt-16 mb-7 block w-full lg:cursor-default"
      >
        <TitleWrapper separatorColor={color}>
          <DoubleTitle doubleTitle={doubleTitle} color={color} />
          <div className="lg:hidden">
            <PlusIcon
              width="2px"
              color={color}
              className={` w-7 sm:w-8 h-7 sm:h-8 ${rotateAnimation}`}
            />
          </div>
        </TitleWrapper>
      </button>
      <HeightAnimation isOpen={isOpen}>{children}</HeightAnimation>
    </div>
  )
}

export default FoldableSection
